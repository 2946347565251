.loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(150deg, #261141, #40b298);
  }

  .loading-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 10em;
  }

  .loading-container span {
    width: 0.5em;
    height: 5em;
    background-color: white;
  }

  .loading-container span:nth-of-type(1) {
    animation: grow 1s -0.45s ease-in-out infinite;
  }

  .loading-container span:nth-of-type(2) {
    animation: grow 1s -0.3s ease-in-out infinite;
  }

  .loading-container span:nth-of-type(3) {
    animation: grow 1s -0.15s ease-in-out infinite;
  }

  .loading-container span:nth-of-type(4) {
    animation: grow 1s ease-in-out infinite;
  }

  @keyframes grow {
    0%,
    100% {
      transform: scaleY(1);
    }

    50% {
      transform: scaleY(2);
    }
  }
