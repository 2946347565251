*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
html {
  font-size: calc (10px + 1vmin);
}
body {

  background-color: #f0f2f4;
}

li {
  list-style: none;
}
a {
  text-decoration: none;
  color: white;
}

.nav {
  position: fixed;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 6rem;
  padding-left: 10%;
  padding-right: 10%;
  color: #261141;

  /* Add a smooth transition effect */
  background: transparent;
}





.nav.active {
  background: white;
  box-shadow: 0px 0px 9px gray;
  height: 6rem;

}

.nav__brand {
  font-size: 1.8rem;
  font-weight: 900;
}
.nav__brand.active1 a {
  font-size: 1.8rem;
  color: #261141;
  font-weight: bolder;
}

.nav__menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1.5rem;
}
.careernav__menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 2rem;
}
.nav__toggler {
  display: none;
}
.nav__toggler div {
  width: 2.5rem;

  height: 0.2rem;
  margin: 0.4rem;
  background: white;
  transition: 0.1s ease-in;

}
.nav__item a {
  font-size: 1.2rem;
  font-weight: 550;
  text-decoration: none; /* Remove the default underline */
  border-bottom: 3px solid transparent; /* Start with a transparent border */
  transition: border-color 0.4s ease-in-out;
}

.nav__item.active1 a {
  color: black;
}
.nav__item.active1 a:hover {
  color: #261141;
}

.nav__item a:hover {
  border-color: white;
}
.nav__item.active1 a:hover {
  border-color: #261141;

}


@media screen and (max-width: 900px) {
  .nav__toggler {
    display: block;
    cursor: pointer;
  }
  .nav {

    padding-left: 10%;
    padding-right: 7%;
    height: 9vh;
    background-color: #261141;
  }
  .nav.active {
    padding-left: 10%;
    padding-right: 7%;
    height: 9vh;
    background-color: #261141;
  }
  .nav__item.active1 a {
    color: white;
  }
  .nav__brand {
    font-size: 1.4rem;
    font-weight: 900;
  }
  .nav__brand.active1 a {
    font-size: 1.4rem;
    color: white;
  }

  .nav__menu {
    position: fixed;
    top: 9vh;
    right: 0;
    height: 24rem;
    width: 100%;
    background: #261141;
    flex-direction: column;
    transform: translateX(100%);
    transition: 0.5s ease-in;
    justify-content: flex-start;
    align-items: center;

    display: flex;
  }
  .careernav__menu {
    position: fixed;
    top: 9vh;
    right: 0;
    height: 24rem;
    width: 100%;
    background: #261141;
    flex-direction: column;
    transform: translateX(100%);
    transition: 0.5s ease-in;
    justify-content: flex-start;
    align-items: center;

    display: flex;
  }
}


@media screen and (min-width:900px) and (max-width: 1024px) {
  .nav {
    position: fixed;
    top: 0;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 7rem;
    padding-left: 10%;
    padding-right: 10%;
    color: #261141;

    /* Add a smooth transition effect */
    background: transparent;
  }





  .nav.active {
    background: white;
    /* box-shadow: 0px 0px 9px gray; */
    max-height: 5rem;
  }

  .nav__brand {
    font-size: 1.5rem;
    font-weight: 900;
  }
  .nav__brand.active1 a {
    font-size: 1.5rem;
    color: #261141;
    font-weight: bolder;
  }

  .nav__menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 2rem;
  }
  .careernav__menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 2rem;
  }
  .nav__toggler {
    display: none;
  }
  .nav__toggler div {
    width: 2.5rem;

    height: 0.2rem;
    margin: 0.4rem;
    background: white;
    transition: 0.3s ease-in;
  }
  .nav__item a {
    font-size: 1rem;
    font-weight: 550;
    text-decoration: none; /* Remove the default underline */
    border-bottom: 3px solid transparent; /* Start with a transparent border */
    transition: border-color 0.4s ease-in-out;
  }
}

/* Active Class */
.nav__active {
  transform: translateX(0%);
}

/* Toggle Icon Animation */

.toggle .line1 {
  transform: rotate(-45deg) translate(-4px, 5px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-4px, -5px);
}

.slick-prev:before,
.slick-next:before {
  display: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.imgbg {
  background-color: white;
  padding: 2rem 1rem;
  border-radius: 10px;
  margin-left: 10px;
  min-height: 15rem;
}

.line::after {
  background-color: #261141;
  content: "";
  display: block;
  height: 0.2rem;
  width: 4rem;
  margin-top: 0.2rem;
}

.workline::after {
  background-color: white;
  content: "";
  display: block;
  height: 0.2rem;
  width: 4rem;
  margin-top: 0.2rem;
  margin-left: auto;
  margin-right: auto;
}
.cookies {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
}
.cookies p {
  width: 100%;
}
@media (max-width: 600px) {
  .cookies {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .cookies p {
    width: 100%;
  }
}
@media screen and (min-width: 600px) and (max-width:768px) {
  .cookies {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100vw;
    padding-left: 2rem;

  }
  .cookies p {

    width: 100%;
  }

  .btn{
    padding:1rem;


  }
}


.afterglow {
  position: relative;
}

.afterglow:after {
  content: "";
  width: 55%;
  height: 35%;
  background: #261141;
  position: absolute;
  bottom: 7%;
  margin: auto;
  filter: blur(80px);
  border-radius: 12px;
  right: 45%;
  transform: translateX(50%);
  z-index: -5;
}
@media screen and (max-width: 480px) {
  .afterglow {
    position: relative;
  }

  .afterglow:after {
    content: "";
    width: 55%;
    height: 35%;
    background: #261141;
    position: absolute;
    bottom: 7%;
    margin: auto;
    filter: blur(40px);
    border-radius: 12px;
    right: 45%;
    transform: translateX(50%);
    z-index: -5;
  }

}

.afterglowcard {
  position: relative;
}

.afterglowcard:after {
  content: "";
  width: 70%;
  height: 70%;
  background: #261141;
  position: absolute;
  bottom: -6%;
  margin: auto;
  filter: blur(80px);
  border-radius: 12px;
  right: 50%;

  transform: translateX(50%);
  z-index: -5;
}


@media screen and (max-width: 480px) {
  .afterglowcard {
    position: relative;
  }

  .afterglowcard:after {
    content: "";
    width: 70%;
    height: 35%;
    background: #261141;
    position: absolute;
    bottom: 1%;
    margin: auto;
    filter: blur(36px);
    border-radius: 12px;
    right: 50%;
    transform: translateX(50%);
    z-index: -5;
  }

}
.polygon-container {
  position: relative;
  width: 6rem; /* Adjust the size as needed */
  height: 6rem; /* Adjust the size as needed */
  /* clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%); */
  background-color: transparent; /* Background color for the polygon shape */
  overflow: hidden;
  box-shadow: inset;
}
.polygon-wrapper {
  padding: 1rem;
}

.polygon-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Adjust the image size to fit the polygon */
}

@media (max-width: 600px) {
  .polygon-container {
    position: relative;
    width: 3rem; /* Adjust the size as needed */
    height: 4rem; /* Adjust the size as needed */
    /* clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%); */
    background-color: transparent; /* Background color for the polygon shape */
    overflow: hidden;
    box-shadow: inset;
  }
  .polygon-wrapper {
    padding: 0rem;
  }
}


/* ----------------------------Blog Header ----------- */
.blognav {
  position: fixed;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 13vh;
  padding-left: 10%;
  padding-right: 10%;
  color: #261141;

  /* Add a smooth transition effect */
  background: white;
}

.blognav__brand a {
  font-size: 1.8rem;
  color: #261141;
  font-weight: bolder;
}